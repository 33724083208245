<template>
    <div>
		<b-modal ref="modalAddSaillie" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.add_saillie") }}
				</template>
			</template>

			<div class="col-12">
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('global.date') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-datepicker class="col" v-model="acte_day" mode="date"></e-datepicker>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('monte.type_saillie') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-select
                                v-model="selected_acte_type"
                                id="actestype_id"
                                track-by="actestype_id"
                                label="actestype_label"
                                :placeholder="$t('acte.selectionnez_acte')"
                                :selectedLabel="$t('global.selected_label')"
                                :options="actes_type_saillie"
                                :searchable="true"
                                :allow-empty="false"
                                :loading="loading_acte_type"
                                :show-labels="false"
                                :group-select="false"
                            >
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
						</div>
					</div>
				</div>

				<CustomTable
					id_table="planning_season_mare"
					ref="planning_season_mare"
					:busy.sync="table_busy"
					:externSlotColumns="extern_slot_columns"
					primaryKey="sms_id"
					:hide_if_empty="true"
					:items="couples"
					:display_action_button="false"
				>
					<template v-slot:[`custom-slot-cell(saillie_time)`]="{ data }">
						<e-datepicker v-model="data.acte_time" mode="time"></e-datepicker>
					</template>
				</CustomTable>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Gynecologie from "@/mixins/Gynecologie.js"
import Common from '@/assets/js/common'
import ActeType from '@/mixins/ActeType.js'
import Actes from '@/mixins/Actes.js'

export default {
	name: "ModalAddSaillie",
	mixins: [Gynecologie, ActeType, Actes],
	props: ['date', 'planning', 'season_id'],
	data () {
		return {
			processing: false,
			table_busy: false,
			acte_day: new Date(),
			couples: [],
			selected_acte_type: null,
			actes_type_saillie: [],
			loading_acte_type: false,
			extern_slot_columns: [
				'saillie_time'
			],
		}
	},
	methods: {
		async init_component() {
			this.acte_day = this.date
			this.couples = []

			this.table_busy = true
			await Common.asyncForEach(this.planning.planning_schedule, async (sch) => {
				for(let horse in sch.schedule_info) {
					await Common.asyncForEach(sch.schedule_info[horse], async (info) => {
						let season_mare = await this.loadSeasonMareStallionBySeasonHorse(info.schedulehorse_mare.horse_id, this.season_id)
						if(season_mare.length > 0) {
							season_mare = season_mare[0]
							let sms = season_mare.seasonmarestallion.find(sms => sms.seasonmarestallion_horse == info.schedulehorse_stallion.horse_id)

							if(sms) {
								this.couples.push({
									sms_id: sms.seasonmarestallion_id,
									acte_time: new Date(),
									stallion_name: info.schedulehorse_stallion.horse_nom,
									mare_name: info.schedulehorse_mare.horse_nom,
									mare_id: info.schedulehorse_mare.horse_id
								})
							}
						}
					})
				}
			})
			this.$refs.planning_season_mare.isCheckedAll = true
			this.table_busy = false

			this.loading_acte_type = true
			this.actes_type_saillie = await this.getActeTypeSaillie()
			this.loading_acte_type = false
		},
		openModal() {
			this.$refs.modalAddSaillie.show()
		},
		closeModal() {
			this.$refs.modalAddSaillie.hide()
		},
		async checkForm() {
			this.processing = true

			if(!this.selected_acte_type) {
				this.processing = false
				return false
			}

			const questions = await this.getQuestionsByActeType(this.selected_acte_type.actestype_id)
			const question_stallion = questions.find(quest => quest.question_code == "Étalon")

			const selected = this.$refs.planning_season_mare.selected

			await Common.asyncForEach(selected, async (sms_id) => {
				const couple = this.couples.find(couple => couple.sms_id == sms_id)

				let acte_date = new Date(this.acte_day.getFullYear() + '/' + parseInt(this.acte_day.getMonth()+1) + '/' + this.acte_day.getDate())
				acte_date.setHours(couple.acte_time.getHours())
				acte_date.setMinutes(couple.acte_time.getMinutes())

				const reponses = [{
					reponse_data: [couple.stallion_name],
					reponse_data_hidden: {
						type: "season_mare_stallion",
						id: couple.sms_id
					},
					reponse_question: question_stallion.question_id
				}]

				let acte_status = 1
				if(this.acte_date > new Date()) {
					acte_status = 0
				}

				await this.addActes([couple.mare_id], acte_status, acte_date, '', this.selected_acte_type.actestype_id, undefined, null, [], reponses, null, null)
			})
			.then(async () => {
				this.processing = false
				this.closeModal()
			})
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
	}
}
</script>