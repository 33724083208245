import { render, staticRenderFns } from "./ModalAddSaillie.vue?vue&type=template&id=511be707&"
import script from "./ModalAddSaillie.vue?vue&type=script&lang=js&"
export * from "./ModalAddSaillie.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports